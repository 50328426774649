<template>
	<nav class="nav nav--fixed">
		<div class="nav__wrap">
			<a href="#" class="nav__btn nav__btn--prev">
				<i class="icon icon-left--white">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text white">
					내가 초대한 회원
				</span>
			</p>
			<a class="nav__btn">
			</a>
		</div>
	</nav>
	<div id="container" class="container">
		<div id="sub" class="sub mypage">
			<section class="top top--fit">
				<div class="mypage__my">
					<div class="mypage__my-info">
						<div class="mypage__my-name">
							<span>
								<strong>만두</strong>님이<br>
								초대한 회원수
							</span>
						</div>
					</div>
					<div class="mypage__my-point">
                        <strong>0</strong>
						<span>
							명
						</span>
					</div>
				</div>
			</section>
			<section class="content">
                <div class="mypage__wrap">
                    <div class="mypage__inner">
                        <div class="textbox textbox--margin" style="margin-top:100px">
                            <div class="textbox__icon">
                                <img src="@/assets/images/icon-recommend.png" alt="찾으시는 결과가 없나요?" style="width: 44px;">
                            </div>
                            <h3 class="textbox__title">내가 초대한 회원이 없어요</h3>
                            <p class="textbox__description"> 친구 초대하고 1000 포인트 받으세요! </p>
                            <div class="textbox__notice">
                                내 추천인코드 : <strong class="skyblue">DADAf123F</strong>
                            </div>
                        </div>
                    </div>
                </div>
			</section>
		</div>
	</div>
</template>

